import config from '@/Framework/config';

export const DATE_PICKER_CONDOR_DATE_FORMAT = 'MM/dd/yyyy';
export const CONDOR_DEAL_FILES_DATE_TIME_FORMAT = 'MM/dd/yyyy hh:mm a';
export const CONDOR_EVENT_LOG_DATE_TIME_FORMAT = 'MM/dd/yyyy hh:mm:ss a';

export const CONDOR_TABLE_DEFAULT_DATE_FORMAT_2 = 'yyyy-MM-dd HH:mm:ss';

export const OLD_CONDOR_ACCOUNTS_AND_CONTACTS_BASE_URL = `${ config.condor.base }/accounts-contacts`;

export const OLD_CONDOR_CONTACTS_BASE_URL = `${ OLD_CONDOR_ACCOUNTS_AND_CONTACTS_BASE_URL }/contacts`;
export const OLD_CONDOR_CONTACTS_LIST_BASE_URL = `${ OLD_CONDOR_CONTACTS_BASE_URL }/list`;
export const OLD_CONDOR_CONTACT_PROFILE_BASE_URL = `${ OLD_CONDOR_CONTACTS_BASE_URL }/profile`;
export const OLD_CONDOR_CONTACT_LINKS_BASE_URL = `${ OLD_CONDOR_CONTACTS_BASE_URL }/links`;
export const OLD_CONDOR_CONTACT_ADVANCED_BASE_URL = `${ OLD_CONDOR_CONTACTS_BASE_URL }/advanced`;
export const OLD_CONDOR_CONTACT_USER_PERMISSIONS_BASE_URL = `${ OLD_CONDOR_CONTACTS_BASE_URL }/user-permissions`;

export const CONDOR_CONTACTS_BASE_URL = `${ config.condor.base }/contacts`;
export const CONDOR_CONTACTS_LIST_BASE_URL = `${ CONDOR_CONTACTS_BASE_URL }/list`;
export const CONDOR_CONTACT_PROFILE_BASE_URL = `${ CONDOR_CONTACTS_BASE_URL }/profile`;
export const CONDOR_CONTACT_LINKS_BASE_URL = `${ CONDOR_CONTACTS_BASE_URL }/links`;
export const CONDOR_CONTACT_ADVANCED_BASE_URL = `${ CONDOR_CONTACTS_BASE_URL }/advanced`;
export const CONDOR_CONTACT_USER_PERMISSIONS_BASE_URL = `${ CONDOR_CONTACTS_BASE_URL }/user-permissions`;
export const CONDOR_CONTACT_PROFILE_FORM_NAME = 'contactProfileForm';
export const CONDOR_CONTACT_TABLE_INLINE_EDIT_FORM_NAME = 'contactsTableInlineEditForm';

export const OLD_CONDOR_ACCOUNTS_BASE_URL = `${ OLD_CONDOR_ACCOUNTS_AND_CONTACTS_BASE_URL }/accounts`;
export const OLD_CONDOR_ACCOUNTS_LIST_BASE_URL = `${ OLD_CONDOR_ACCOUNTS_BASE_URL }/list`;
export const OLD_CONDOR_CREATE_ACCOUNT_BASE_URL = `${ OLD_CONDOR_ACCOUNTS_BASE_URL }/create`;
export const OLD_CONDOR_ACCOUNT_PROFILE_BASE_URL = `${ OLD_CONDOR_ACCOUNTS_BASE_URL }/profile`;
export const OLD_CONDOR_ACCOUNT_PROFILE_MAPPINGS_BASE_URL = `${ OLD_CONDOR_ACCOUNTS_BASE_URL }/mappings`;

export const CONDOR_ACCOUNTS_BASE_URL = `${ config.condor.base }/accounts`;
export const CONDOR_ACCOUNTS_LIST_BASE_URL = `${ CONDOR_ACCOUNTS_BASE_URL }/list`;
export const CONDOR_CREATE_ACCOUNT_BASE_URL = `${ CONDOR_ACCOUNTS_BASE_URL }/create`;
export const CONDOR_ACCOUNT_PROFILE_BASE_URL = `${ CONDOR_ACCOUNTS_BASE_URL }/profile`;
export const CONDOR_ACCOUNT_PROFILE_MAPPINGS_BASE_URL = `${ CONDOR_ACCOUNTS_BASE_URL }/mappings`;
export const CONDOR_ACCOUNT_PROFILE_SSO_BASE_URL = `${ CONDOR_ACCOUNTS_BASE_URL }/SSO`;
export const CONDOR_ACCOUNT_PROFILE_FORM_NAME = 'accountProfileForm';
export const CONDOR_ACCOUNT_TABLE_INLINE_EDIT_FORM_NAME = 'accountsTableInlineEditForm';

export const OLD_CONDOR_ACCOUNT_TYPES_BASE_URL = `${ OLD_CONDOR_ACCOUNTS_AND_CONTACTS_BASE_URL }/account-types`;
export const OLD_CONDOR_ACCOUNT_TYPES_LIST_BASE_URL = `${ OLD_CONDOR_ACCOUNT_TYPES_BASE_URL }/list`;
export const OLD_CONDOR_CREATE_ACCOUNT_TYPE_BASE_URL = `${ OLD_CONDOR_ACCOUNT_TYPES_BASE_URL }/create`;
export const OLD_CONDOR_ACCOUNT_TYPE_PROFILE_BASE_URL = `${ OLD_CONDOR_ACCOUNT_TYPES_BASE_URL }/profile`;

export const OLD_CONDOR_GLOBAL_BLACKLIST_BASE_URL = `${ OLD_CONDOR_ACCOUNTS_AND_CONTACTS_BASE_URL }/global-blacklist`;
export const OLD_CONDOR_APPLICATION_BLACKLIST_BASE_URL = `${ OLD_CONDOR_ACCOUNTS_AND_CONTACTS_BASE_URL }/application-blacklist`;

export const CONDOR_ADMIN_BASE_URL = `${ config.condor.base }/admin`;

export const CONDOR_ADMIN_BANNER_BASE_URL = `${ CONDOR_ADMIN_BASE_URL }/banner`;
export const CONDOR_ADMIN_BANNER_LIST_BASE_URL = `${ CONDOR_ADMIN_BANNER_BASE_URL }/list`;

export const CONDOR_ACCOUNT_TYPES_BASE_URL = `${ CONDOR_ADMIN_BASE_URL }/account-types`;
export const CONDOR_ACCOUNT_TYPES_LIST_BASE_URL = `${ CONDOR_ACCOUNT_TYPES_BASE_URL }/list`;
export const CONDOR_CREATE_ACCOUNT_TYPE_BASE_URL = `${ CONDOR_ACCOUNT_TYPES_BASE_URL }/create`;
export const CONDOR_ACCOUNT_TYPE_PROFILE_BASE_URL = `${ CONDOR_ACCOUNT_TYPES_BASE_URL }/profile`;
export const CONDOR_ACCOUNT_TYPE_PROFILE_FORM_NAME = 'accountTypeProfileForm';

export const CONDOR_GLOBAL_BLACKLIST_BASE_URL = `${ CONDOR_ADMIN_BASE_URL }/global-blacklist`;
export const CONDOR_APPLICATION_BLACKLIST_BASE_URL = `${ CONDOR_ADMIN_BASE_URL }/application-blacklist`;

export const CONDOR_FINSIGHT_BASE_URL = `${ config.condor.base }/finsight`;

export const CONDOR_FINSIGHT_DEAL_NO_PRICING_DATE_BASE_URL = `${ CONDOR_FINSIGHT_BASE_URL }/deal-no-pricing-date`;

export const CONDOR_FINSIGHT_COMPANIES_BASE_URL = `${ CONDOR_FINSIGHT_BASE_URL }/companies`;
export const CONDOR_FINSIGHT_COMPANIES_LIST_BASE_URL = `${ CONDOR_FINSIGHT_COMPANIES_BASE_URL }/list`;
export const CONDOR_FINSIGHT_CREATE_COMPANY_BASE_URL = `${ CONDOR_FINSIGHT_COMPANIES_BASE_URL }/create`;
export const CONDOR_FINSIGHT_COMPANY_PROFILE_BASE_URL = `${ CONDOR_FINSIGHT_COMPANIES_BASE_URL }/profile`;
export const CONDOR_FINSIGHT_COMPANY_DEALS_BASE_URL = `${ CONDOR_FINSIGHT_COMPANIES_BASE_URL }/deals`;

export const CONDOR_FINSIGHT_SUBSCRIBERS_BASE_URL = `${ CONDOR_FINSIGHT_BASE_URL }/subscribers`;
export const CONDOR_FINSIGHT_TRACE_BASE_URL = `${ CONDOR_FINSIGHT_BASE_URL }/trace`;
export const CONDOR_FINSIGHT_TRADES_BASE_URL = `${ CONDOR_FINSIGHT_TRACE_BASE_URL }/trades`;
export const CONDOR_FINSIGHT_TRADES_SUBPRODUCT_URL = `${ CONDOR_FINSIGHT_TRACE_BASE_URL }/trades:subProductType`;
export const CONDOR_FINSIGHT_TRADES_ARCHIVED_BASE_URL = `${ CONDOR_FINSIGHT_TRACE_BASE_URL }/archived`;

export const CONDOR_FINSIGHT_COMPANY_PROFILE_FORM_NAME = 'companyProfileForm';
export const CONDOR_FINSIGHT_COMPANY_TABLE_INLINE_EDIT_FORM_NAME = 'companiesTableInlineEditForm';

export const CONDOR_FINSIGHT_DEAL_BASE_URL = `${ CONDOR_FINSIGHT_BASE_URL }/deal`;
export const CONDOR_FINSIGHT_DEAL_PROFILE_BASE_URL = `${ CONDOR_FINSIGHT_DEAL_BASE_URL }/profile`;
export const CONDOR_FINSIGHT_CREATE_DEAL_BASE_URL = `${ CONDOR_FINSIGHT_DEAL_BASE_URL }/create`;

export const CONDOR_FINSIGHT_FILINGS_BASE_URL = `${ CONDOR_FINSIGHT_BASE_URL }/filings`;
export const CONDOR_FINSIGHT_FILINGS_15G_BASE_URL = `${ CONDOR_FINSIGHT_FILINGS_BASE_URL }/15g`;
export const CONDOR_FINSIGHT_FILINGS_EE_BASE_URL = `${ CONDOR_FINSIGHT_FILINGS_BASE_URL }/ee`;
export const CONDOR_FINSIGHT_FILINGS_TYPE_BASE_URL = `${ CONDOR_FINSIGHT_FILINGS_BASE_URL }/:filingType`;

export const CONDOR_FINSIGHT_FILING_CREATE_URL = `${ CONDOR_FINSIGHT_FILINGS_TYPE_BASE_URL }/create`;
export const CONDOR_FINSIGHT_FILING_PROFILE_URL = `${ CONDOR_FINSIGHT_FILINGS_TYPE_BASE_URL }/profile`;
export const CONDOR_FINSIGHT_FILING_LINK_TO_EXISTING_DEAL_URL =
  `${ CONDOR_FINSIGHT_FILINGS_TYPE_BASE_URL }/link-to-existing-deal`;

export const CONDOR_FINSIGHT_IMPORT_BASE_URL = `${ CONDOR_FINSIGHT_BASE_URL }/import`;

export const CONDOR_FINSIGHT_IMPORT_BOND_BASE_URL = `${ CONDOR_FINSIGHT_IMPORT_BASE_URL }/bond`;
export const CONDOR_FINSIGHT_IMPORT_BOND_UPLOAD_BASE_URL = `${ CONDOR_FINSIGHT_IMPORT_BOND_BASE_URL }/upload`;
export const CONDOR_FINSIGHT_IMPORT_BOND_LIST_BASE_URL = `${ CONDOR_FINSIGHT_IMPORT_BOND_BASE_URL }/list`;

export const CONDOR_FINSIGHT_IMPORT_DEAL_BASE_URL = `${ CONDOR_FINSIGHT_IMPORT_BASE_URL }/deal`;
export const CONDOR_FINSIGHT_IMPORT_DEAL_UPLOAD_BASE_URL = `${ CONDOR_FINSIGHT_IMPORT_DEAL_BASE_URL }/upload`;
export const CONDOR_FINSIGHT_IMPORT_DEAL_AUDIT_TRAIL_BASE_URL = `${ CONDOR_FINSIGHT_IMPORT_DEAL_BASE_URL }/audit-trail`;

export const CONDOR_FINSIGHT_IMPORT_COMPANY_BASE_URL = `${ CONDOR_FINSIGHT_IMPORT_BASE_URL }/company`;
export const CONDOR_FINSIGHT_IMPORT_COMPANY_UPLOAD_BASE_URL = `${ CONDOR_FINSIGHT_IMPORT_COMPANY_BASE_URL }/upload`;
export const CONDOR_FINSIGHT_IMPORT_COMPANY_AUDIT_TRAIL_BASE_URL = `${ CONDOR_FINSIGHT_IMPORT_COMPANY_BASE_URL }/audit-trail`;

export const CONDOR_DEAL_PROFILE_FORM_NAME = 'dealProfileForm';
export const CONDOR_FINSIGHT_FILING_LINK_TO_EXISTING_DEAL_FORM_NAME = 'linkToExistingDealForm';

export const CONDOR_FINSIGHT_COMPANY_DEAL_TABLE_INLINE_EDIT_FORM_NAME = 'dealsTableInlineEditForm';

export const CONDOR_FINSIGHT_MIGRATE_COMPANY_DEALS_FORM = 'companyMigrateDealsForm';
export const CONDOR_FINSIGHT_IMPORT_COMPANY_SELECT_FORM = 'importCompanySelectForm';

export const CONDOR_FINSIGHT_ACCOUNT_MAPPING_FORM = 'CONDOR_FINSIGHT_ACCOUNT_MAPPING_FORM';

export const CONDOR_TECHNICAL_BASE_URL = `${ config.condor.base }/technical`;
export const CONDOR_TECHNICAL_DRS_EVENT_LOG_OFFSET_URL = `${ CONDOR_TECHNICAL_BASE_URL }/drs/event-log-offset`;
export const CONDOR_TECHNICAL_FINSIGHT_EVENT_LOG_OFFSET_URL = `${ CONDOR_TECHNICAL_BASE_URL }/finsight/event-log-offset`;
export const CONDOR_TECHNICAL_FINSIGHT_STRATS_EVENT_LOG_OFFSET_URL = `${ CONDOR_TECHNICAL_BASE_URL }/finsight-strats/event-log-offset`;
export const CONDOR_TECHNICAL_FINSIGHT_ANALYTICS_EVENT_LOG_OFFSET_URL = `${ CONDOR_TECHNICAL_BASE_URL }/finsight/analytics/event-log-offset`;
export const CONDOR_TECHNICAL_DM_PORTAL_EVENT_LOG_OFFSET_URL = `${ CONDOR_TECHNICAL_BASE_URL }/dm-portal/event-log-offset`;
export const CONDOR_TECHNICAL_CONDOR_EVENT_LOG_OFFSET_URL = `${ CONDOR_TECHNICAL_BASE_URL }/condor/event-log-offset`;
export const CONDOR_TECHNICAL_DATABASES_URL = `${ CONDOR_TECHNICAL_BASE_URL }/databases`;

export const CONDOR_DRS_BASE_URL = `${ config.drs.condor.base }`;
export const CONDOR_DRS_ROADSHOWS_URL = `${ CONDOR_DRS_BASE_URL }/roadshows`;
export const CONDOR_DRS_ROADSHOW_URL = `${ CONDOR_DRS_ROADSHOWS_URL }/roadshow`;

export const CONDOR_FINSIGHT_MANAGER_BASE_URL = `${ config.condor.base }/fin-manager`;

export const CONDOR_FINSIGHT_MANAGER_ANALYTICS_URL = `${ CONDOR_FINSIGHT_MANAGER_BASE_URL }/analytics`;
export const CONDOR_FINSIGHT_MANAGER_ANALYTICS_BI_URL = `${ CONDOR_FINSIGHT_MANAGER_BASE_URL }/analytics/bi`;
export const CONDOR_FINSIGHT_MANAGER_ANALYTICS_TRACE_URL = `${ CONDOR_FINSIGHT_MANAGER_BASE_URL }/analytics/trace`;

export const CONDOR_FINSIGHT_MANAGER_MANAGE_PLANS_BASE_URL = `${ CONDOR_FINSIGHT_MANAGER_BASE_URL }/manage-plans`;
export const CONDOR_FINSIGHT_MANAGER_MANAGE_PLANS_REQUESTS_URL = `${ CONDOR_FINSIGHT_MANAGER_MANAGE_PLANS_BASE_URL }/requests`;
export const CONDOR_FINSIGHT_MANAGER_MANAGE_PLANS_SUBSCRIBERS_URL = `${ CONDOR_FINSIGHT_MANAGER_MANAGE_PLANS_BASE_URL }/subscribers`;

export const CONDOR_FINSIGHT_OPEN_MARKET = `${ config.condor.base }/openmarket`;
export const CONDOR_FINSIGHT_OPEN_MARKET_PROFILES = `${ CONDOR_FINSIGHT_OPEN_MARKET }/profiles`;
export const CONDOR_FINSIGHT_OPEN_MARKET_PROFILE_EVENTS_LIST = `${ CONDOR_FINSIGHT_OPEN_MARKET_PROFILES }/:profileId/events`;
export const CONDOR_FINSIGHT_OPEN_MARKET_PROFILES_CREATE = `${ CONDOR_FINSIGHT_OPEN_MARKET_PROFILES }/create`;
export const CONDOR_FINSIGHT_OPEN_MARKET_PROFILES_EDIT = `${ CONDOR_FINSIGHT_OPEN_MARKET_PROFILES }/:profileId/edit`;
export const CONDOR_FINSIGHT_OPEN_MARKET_EVENTS = `${ CONDOR_FINSIGHT_OPEN_MARKET }/events`;
export const CONDOR_FINSIGHT_OPEN_MARKET_EVENTS_CREATE = `${ CONDOR_FINSIGHT_OPEN_MARKET_EVENTS }/create`;
export const CONDOR_FINSIGHT_OPEN_MARKET_EVENTS_EDIT = `${ CONDOR_FINSIGHT_OPEN_MARKET_EVENTS }/:eventId/edit`;

export const CONDOR_EVERCALL_BASE_URL = `${ config.condor.base }/evercall`;
export const CONDOR_EVERCALL_LIST = `${ CONDOR_EVERCALL_BASE_URL }/list`;
export const CONDOR_EVERCALL_PROFILE = `${ CONDOR_EVERCALL_BASE_URL }/profile`;
export const CONDOR_EVERCALL_GROUP_CREATE = `${ CONDOR_EVERCALL_BASE_URL }/create-group`;
export const CONDOR_EVERCALL_GROUP_PROFILE = `${ CONDOR_EVERCALL_BASE_URL }/group`;

export const CONDOR_DEALVDR_LIST_URL = `${ config.condor.base }/dealvdr`;
export const CONDOR_INVESTORSET_LIST_URL = `${ config.condor.base }/investorset`;
export const CONDOR_17G5_LIST_URL = `${ config.condor.base }/17g5`;
export const CONDOR_VERISEND_LIST_URL = `${ config.condor.base }/verisend`;
export const CONDOR_RESEARCHROOM_LIST_URL = `${ config.condor.base }/researchroom`;

export const CONDOR_ENHANCED_TAGGING_BASE_URL = `${ config.condor.base }/enhanced-tagging`;
export const CONDOR_ENHANCED_TAGGING_DRS = `${ CONDOR_ENHANCED_TAGGING_BASE_URL }/roadshow`;
